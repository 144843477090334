import { useMemo } from "react";

import { ExportMapTypes } from "../../../types/exports/exportMap/exportMap";
import { VisibleColumns } from "../../../types/grid/export";
import { SidebarNavigationMenu } from "../../../types/panels/sidebar";

import { MAX_EXPORT } from "../../../constants/constants";
import {
  EXPORT_FILE_GEO_DB,
  EXPORT_GRID_TO_EXCEL,
  EXPORT_MANAGER,
  EXPORT_SHAPE_FILE,
} from "../../../constants/export/exportNavbar";
import { NON_ATTRIBUTE_COLUMNS } from "../../../constants/grid";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useExportCardStore from "../../../store/exportCard/exportCardStore";
import useExportMapStore from "../../../store/exportMap/exportMapStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useStore from "../../../store/useStore";

import useAllWellDataForExport from "../../../customHooks/useAllWellDataForExport";

import AccordionNavMenu from "./AccordionNavMenu";

interface ExportNavAccordionProps {
  menuInfo: SidebarNavigationMenu;
  isDisabled: boolean;
  isNavbarExpanded: boolean;
}

const ExportNavAccordion = ({
  menuInfo,
  isDisabled,
  isNavbarExpanded,
}: ExportNavAccordionProps) => {
  const lastSearchedBy = useStore((state) => state.lastSearchedBy);
  const updateExportPanelMenuTrigger = useExportCardStore(
    (state) => state.updateExportPanelMenuTrigger
  );
  const isExportManagerLoading = useExportCardStore(
    (state) => state.isExportManagerLoading
  );

  const exportMapModalOpened = useExportMapStore(
    (state) => state.exportMapModalOpened
  );
  const updateExportMapModalOpened = useExportMapStore(
    (state) => state.updateExportMapModalOpened
  );
  const isExportMapLoading = useExportMapStore(
    (state) => state.isExportMapLoading
  );

  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );
  const isExportToGridLoading = useDataGridStore(
    (state) => state.isExportToGridLoading
  );
  const concatBatchesWellGridData = useDataGridStore(
    (state) => state.concatBatchesWellGridData
  );
  const apiRef = useDataGridStore((state) => state.apiRef);

  const { exportDataAsExcelCallback } = useAllWellDataForExport();

  const isExportManagerDisabled = useMemo(
    () =>
      lastSearchedBy === SEARCH_TYPES.ATTRIBUTE_SEARCH &&
      gridTotalCount > MAX_EXPORT,
    [lastSearchedBy, gridTotalCount]
  );

  const isExportToExcelDisabled = useMemo(
    () => gridFilteredCount > MAX_EXPORT || isExportToGridLoading,
    [gridFilteredCount, isExportToGridLoading]
  );

  const isAnyExportLoading = useMemo(
    () => isExportManagerLoading || isExportToGridLoading || isExportMapLoading,
    [isExportManagerLoading, isExportToGridLoading, isExportMapLoading]
  );

  const openExportManager = () => {
    updateExportPanelMenuTrigger(true);
  };

  const openMapExportModal = (exportMapType: ExportMapTypes) => {
    updateExportMapModalOpened(exportMapType);
  };

  const exportGridToExcel = () => {
    if (!apiRef) return;
    const visibleColumns: VisibleColumns[] = apiRef.current
      .getVisibleColumns()
      .filter((col) => !NON_ATTRIBUTE_COLUMNS.includes(col.field))
      .map((filteredCol) => {
        return {
          field: filteredCol.field,
          header: filteredCol.headerName,
        };
      });
    exportDataAsExcelCallback(concatBatchesWellGridData, visibleColumns);
  };

  return (
    <AccordionNavMenu
      menuInfo={menuInfo}
      isDisabled={isDisabled || isAnyExportLoading}
      isLoading={isAnyExportLoading}
      isNavbarExpanded={isNavbarExpanded}
      isModalOpen={!!exportMapModalOpened}
      className="export-nav-accordion"
      listItems={[
        {
          key: EXPORT_MANAGER,
          label: "Export Manager",
          isDisabled: isExportManagerDisabled,
          subLabel: "(100k max)",
          onClick: openExportManager,
        },
        {
          key: EXPORT_SHAPE_FILE,
          label: "Export ShapeFile",
          isDisabled: false,
          subLabel: "",
          onClick: () => openMapExportModal(EXPORT_SHAPE_FILE),
        },
        {
          key: EXPORT_FILE_GEO_DB,
          label: "Export FileGeoDB",
          isDisabled: false,
          subLabel: "",
          onClick: () => openMapExportModal(EXPORT_FILE_GEO_DB),
        },
        {
          key: EXPORT_GRID_TO_EXCEL,
          label: "Export to Excel",
          isDisabled: isExportToExcelDisabled,
          subLabel: "(100k max)",
          onClick: exportGridToExcel,
        },
      ]}
    />
  );
};

export default ExportNavAccordion;
