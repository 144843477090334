import { useEffect, useMemo, useState } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";

import classNames from "classnames";
import { Button } from "component-library";

import {
  ExportMapLayerNames,
  ExportMapTypes,
} from "../../../types/exports/exportMap/exportMap";

import {
  BOTTOM_WELL_SPOTS,
  SURFACE_WELL_SPOTS,
  WELL_PATHS,
} from "../../../constants/constants";
import { EXPORT_MAP_LAYER_NAMES } from "../../../constants/export/exportMap";
import { EXPORT_SHAPE_FILE } from "../../../constants/export/exportNavbar";

import useMapExportStore from "../../../store/exportMap/exportMapStore";

import useExportMap from "../../../customHooks/exports/exportMap/useExportMap";

import CustomCheckbox from "../../common/CustomCheckbox";
import TooltipInfoIcon from "../../common/icons/TooltipInfoIcon";

const ExportMapModal = () => {
  const exportMapModalOpened = useMapExportStore(
    (state) => state.exportMapModalOpened
  );
  const updateExportMapModalOpened = useMapExportStore(
    (state) => state.updateExportMapModalOpened
  );

  const { exportMap, isExportMapLoading } = useExportMap();

  const initialLayerSelectionState = {
    [SURFACE_WELL_SPOTS]: false,
    [BOTTOM_WELL_SPOTS]: false,
    [WELL_PATHS]: false,
  };

  const [layerSelection, setLayerSelection] = useState(
    initialLayerSelectionState
  );

  const exportMapType = useMemo(
    () => exportMapModalOpened ?? "",
    [exportMapModalOpened]
  );

  const hasLayerSelected = useMemo(
    () => Object.values(layerSelection).some((v) => v),
    [layerSelection]
  );

  const handleClose = () => {
    updateExportMapModalOpened(null);
    setLayerSelection(initialLayerSelectionState);
  };

  const handleSelectionChange = (layerName: ExportMapLayerNames) => {
    setLayerSelection((prevState) => ({
      ...prevState,
      [layerName]: !prevState[layerName],
    }));
  };

  const handleExport = () => {
    const layersSelected: string[] = [];
    Object.entries(layerSelection).forEach(([layerName, isSelected]) => {
      if (isSelected) layersSelected.push(layerName);
    });

    exportMap(
      exportMapType as ExportMapTypes,
      layersSelected as ExportMapLayerNames[]
    );
  };

  useEffect(() => {
    if (!isExportMapLoading) {
      handleClose();
    }
  }, [isExportMapLoading]);

  return (
    <Dialog
      open={!!exportMapModalOpened}
      onClose={handleClose}
      className={classNames("export-map-modal", {
        shapefile: exportMapType === EXPORT_SHAPE_FILE,
      })}
    >
      <DialogTitle>
        <div className="dialog-title-container">
          {`Export ${exportMapType}`}
          <IconButton disableRipple size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="message-section">
          <div>
            <TooltipInfoIcon pathFill="#FFFFFF" />
          </div>
          <div className="message-text">
            All Spots/Paths in your total search results will get exported with
            the attributes displayed in your search results grid. Surface and
            Bottom well locations are required to be populated in the respective
            exports.
            {exportMapType === EXPORT_SHAPE_FILE &&
              " Shapefiles larger than 2GB will be delivered as multiple files."}
          </div>
        </div>
        <div className="layer-selection-section">
          <Typography className="include-label">Include:</Typography>
          <div className="layer-list">
            {EXPORT_MAP_LAYER_NAMES.map((layerName) => (
              <FormControlLabel
                key={layerName}
                control={
                  <CustomCheckbox
                    checked={layerSelection[layerName]}
                    disabled={isExportMapLoading}
                    onChange={() => handleSelectionChange(layerName)}
                  />
                }
                label={layerName}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          type="tertiary"
          iconLeft="chevronLeft"
          text="CANCEL"
          disabled={isExportMapLoading}
          onClick={handleClose}
        />
        <Button
          type="primary"
          text="EXPORT"
          disabled={isExportMapLoading || !hasLayerSelected}
          onClick={handleExport}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ExportMapModal;
