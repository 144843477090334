import {
  GetCartoLineColor,
  GetCartoLineWidthPixels,
  GetCartoPointRadius,
  GetHighlightCartoConfig,
  GetUpdateTriggersPointRadius,
  GetWellPathStickLineColor,
  GetWellSpotLineColor,
} from "../../../types/map/layers/styleUtils";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
  HOVER_WELL_PATH_AND_STICK,
  SELECTED_LINE,
  UNSELECTED_LINE,
  WELL_PATHS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
  WELL_STICKS,
} from "../../../constants/constants";
import { LINE_WIDTH_PIXELS } from "../../../constants/map/cartoStyles";

import { getColorByAttribute } from "../../../data/map/utils/colorAggregator";
import { getSizeByAttribute } from "../../../data/map/utils/sizeAggregator";

import { getWellIDOnFeature } from "../../common/wells";
import {
  getWellCardSelectionMultiplier,
  isWellSelectedForWellPanel,
} from "./cartoUtils";

export const getWellSpotLineColor = ({
  feature,
  layerName,
  selectedMapParentWellIDs,
  selectedBottomWellboreIDs,
  isHighlightSelectedSpots,
}: GetWellSpotLineColor) => {
  const wellID = getWellIDOnFeature(feature, layerName);

  const isSelectedParentWellID =
    !!wellID && !!selectedMapParentWellIDs?.[wellID];
  const isSelectedBottomWellboreID =
    !!wellID && !!selectedBottomWellboreIDs?.[wellID];

  let isWellSelected = false;
  if (layerName === DYNAMIC_WELL_SPOTS) {
    isWellSelected = isSelectedParentWellID;
  } else if (layerName === DYNAMIC_BOTTOM_WELL_SPOTS) {
    isWellSelected = isSelectedBottomWellboreID;
  }

  return isHighlightSelectedSpots && isWellSelected
    ? SELECTED_LINE
    : UNSELECTED_LINE;
};

export const getWellPathStickLineColor = ({
  feature,
  layerName,
  colorByAttribute,
  layerColors,
  compressedWellSpotData,
  compressedWellSpotInfo,
  defaultColor,
}: GetWellPathStickLineColor) => {
  return getColorByAttribute({
    colorByAttribute,
    compressedWellSpotData,
    compressedWellSpotInfo,
    feature,
    layerName,
    wellColorList: layerColors,
    defaultColor,
  });
};

export const getCartoLineColor = ({
  feature,
  layerName,
  colorByAttribute,
  layerColors,
  selectedMapParentWellIDs,
  selectedBottomWellboreIDs,
  compressedWellSpotData,
  compressedWellSpotInfo,
  isHighlightSelectedSpots,
  defaultColor,
}: GetCartoLineColor) => {
  switch (layerName) {
    case DYNAMIC_WELL_SPOTS:
    case DYNAMIC_BOTTOM_WELL_SPOTS:
      return getWellSpotLineColor({
        feature,
        layerName,
        selectedMapParentWellIDs,
        selectedBottomWellboreIDs,
        isHighlightSelectedSpots,
      });
    case WELL_PATHS:
    case WELL_STICKS:
      return getWellPathStickLineColor({
        feature,
        layerName,
        colorByAttribute,
        layerColors,
        compressedWellSpotData,
        compressedWellSpotInfo,
        defaultColor,
      });
    default:
      return defaultColor;
  }
};

export const getCartoLineWidthPixels = ({
  feature,
  layerName,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
}: GetCartoLineWidthPixels) => {
  if (layerName === BUBBLE_MAP) return LINE_WIDTH_PIXELS[BUBBLE_MAP];

  const isSelected = isWellSelectedForWellPanel({
    f: feature,
    selectedWellCardPWIDs,
    selectedWellCardBWIDs,
  });

  if (layerName === WELL_PATH_AND_STICKS && isSelected) {
    return LINE_WIDTH_PIXELS[WELL_PATH_AND_STICKS];
  }
  return LINE_WIDTH_PIXELS.DEFAULT;
};

export const getCartoOpacity = (layerName: string) => {
  if (layerName === BUBBLE_MAP) return 0.1;
  return 1;
};

export const getCartoPointRadiusUnits = (layerName: string) => {
  if (
    layerName === WELL_SPOTS ||
    layerName === BOTTOM_WELL_SPOTS ||
    layerName === BUBBLE_MAP
  ) {
    return "pixels";
  }
  return "meters";
};

// radius are calculated in meters or pixels
export const getCartoPointRadius = ({
  feature,
  layerName,
  layerSize,
  zoom,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  compressedBubbleMapData,
  compressedBubbleMapInfo,
  sizeByAttribute,
  bubbleMapTitle,
}: GetCartoPointRadius) => {
  let size = layerSize as number;

  if (layerName === BUBBLE_MAP) {
    const sizeByAttr = getSizeByAttribute(
      sizeByAttribute,
      compressedBubbleMapData,
      compressedBubbleMapInfo,
      feature,
      bubbleMapTitle,
      0
    );
    size = sizeByAttr > 0 ? size + sizeByAttr : 0;
  }

  const finalSize = (zoom + 1) * (size / 300);

  return (
    finalSize *
    getWellCardSelectionMultiplier({
      f: feature,
      selectedWellCardPWIDs,
      selectedWellCardBWIDs,
      layerName,
    })
  );
};

export const getUpdateTriggersPointRadius = ({
  layerName,
  layerSize,
  zoom,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  compressedBubbleMapData,
  compressedBubbleMapInfo,
}: GetUpdateTriggersPointRadius) => {
  const commonUpdateTriggers = [
    layerSize,
    zoom,
    selectedWellCardPWIDs,
    selectedWellCardBWIDs,
  ];

  if (layerName === BUBBLE_MAP) {
    return [
      ...commonUpdateTriggers,
      compressedBubbleMapData,
      compressedBubbleMapInfo,
    ];
  }

  return commonUpdateTriggers;
};

export const getHighlightCartoConfig: GetHighlightCartoConfig = (
  layerName,
  initialGridSearchMade
) => {
  if (layerName === WELL_PATH_AND_STICKS && initialGridSearchMade) {
    return {
      uniqueIdProperty: "BottomWellboreID",
      autoHighlight: true,
      highlightColor: HOVER_WELL_PATH_AND_STICK,
    };
  }
  return {};
};
