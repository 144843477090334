import * as ATTRIBUTE from "../attributes";
import {
  BOTTOM_WELL_SPOTS,
  BOUGUER_ALY,
  ISOSTATIC_ALY,
  LAND_GRIDS,
  MAGNETIC_ALY,
  MAJOR_BASINS,
  MAP_SEARCH_PIPELINES,
  OFFSHORE_BLOCKS,
  PERMIT_SPOTS,
  SCOOP_STACK_PLAY_AREAS,
  SHADED_ALY,
  STRATIGRAPHIC_MODELS_AREA,
  STRUCTURAL_ELEMENTS,
  TGS_SURVEYS,
  TRENDS,
  WELL_SPOTS,
  WOODFORD_FIELDS,
  WOODFORD_ISOPACH,
  WOODFORD_LIMIT,
  WOODFORD_MATURITY,
  WOODFORD_STRUCTURE,
} from "../constants";

export const SMALL = { key: 150, label: "Small" };
export const MEDIUM = { key: 200, label: "Medium (Default)" };
export const LARGE = { key: 400, label: "Large" };
export const WELL_SPOT_SIZES = [SMALL, MEDIUM, LARGE];

export const UNIFORM_COLOR = {
  key: "uniform",
  label: "Uniform Color (Default)",
  dataType: "none",
  // isHidden: false,
};
export const BY_ATTRIBUTE = {
  key: "attribute",
  label: "Attribute",
  // isHidden: true,
};
export const COLOR_BY_OPTIONS = [UNIFORM_COLOR, BY_ATTRIBUTE];

export const SIZE = "size";
export const DEFAULT_SIZE = "defaultSize";
export const COLOR = "color";
export const DEFAULT_COLOR = "defaultColor";
export const LINE_COLOR = "line color";
export const STYLE_KEYS = [SIZE, COLOR];

export const COLOR_BY_ATTRIBUTE_LIST = [
  ATTRIBUTE.LEASE_NAME,
  ATTRIBUTE.FIELD_NAME,
  ATTRIBUTE.BASIN_NAME,
  ATTRIBUTE.SLANT,
  ATTRIBUTE.WELL_STATUS,
  ATTRIBUTE.WELL_TYPE,
  ATTRIBUTE.STATE_LEASE_ID,
  ATTRIBUTE.OPERATOR_NAME,
  ATTRIBUTE.ULTIMATE_OWNER,
  ATTRIBUTE.INTERPRETED_PRODUCING_FORMATION,
  ATTRIBUTE.DISPLAY_FORMATION,
  ATTRIBUTE.MEASURED_DEPTH,
  ATTRIBUTE.TOTAL_VERTICAL_DEPTH,
  ATTRIBUTE.PLUG_DATE,
  ATTRIBUTE.SPUD_DATE,
  ATTRIBUTE.TD_DATE,
  ATTRIBUTE.FIRST_MONTH,
  ATTRIBUTE.LAST_MONTH,
  ATTRIBUTE.COMPLETION_DATE,
  ATTRIBUTE.LATERAL_LENGTH,
  ATTRIBUTE.PERF_INTERVAL_TOP,
  ATTRIBUTE.PERF_INTERVAL_BOTTOM,
  ATTRIBUTE.ACID_AMOUNT,
  ATTRIBUTE.FRAC_FLUID_AMOUNT,
  ATTRIBUTE.FRAC_FLUID_AMOUNT_PER_FT,
  ATTRIBUTE.PROPPANT_AMOUNT,
  ATTRIBUTE.PROPPANT_AMOUNT_PER_FT,
  ATTRIBUTE.OIL_CUM_PER_FT,
  ATTRIBUTE.OIL_EUR_PER_FT,
  ATTRIBUTE.MAX_OIL,
  ATTRIBUTE.MAX_OIL_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_OIL_REMAINING,
  ATTRIBUTE.CUM_OIL,
  ATTRIBUTE.WELL_FORECAST_OIL_ULTIMATE,
  ATTRIBUTE.GAS_CUM_PER_FT,
  ATTRIBUTE.GAS_EUR_PER_FT,
  ATTRIBUTE.MAX_GAS,
  ATTRIBUTE.MAX_GAS_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_GAS_REMAINING,
  ATTRIBUTE.CUM_GAS,
  ATTRIBUTE.WELL_FORECAST_GAS_ULTIMATE,
  ATTRIBUTE.BOE_CUM_PER_FT,
  ATTRIBUTE.EUR_PER_FT,
  ATTRIBUTE.MAX_BOE,
  ATTRIBUTE.BOE_MAX_PER_FT,
  ATTRIBUTE.BOE_IP90,
  ATTRIBUTE.WELL_FORECAST_BOE_REMAINING,
  ATTRIBUTE.CUM_BOE,
  ATTRIBUTE.WELL_FORECAST_BOE_ULTIMATE,
  ATTRIBUTE.WATER_CUM_PER_FT,
  ATTRIBUTE.MAX_WATER,
  ATTRIBUTE.MAX_WATER_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_WATER_REMAINING,
  ATTRIBUTE.CUM_WATER,
  ATTRIBUTE.WELL_FORECAST_WATER_ULTIMATE,
  ATTRIBUTE.WATER_CUT_TOTAL_CUM,
  ATTRIBUTE.WELL_FORECAST_WATER_CUT_ULTIMATE,
  ATTRIBUTE.CUM_GOR,
  ATTRIBUTE.WELL_FORECAST_GOR_ULTIMATE,
  ATTRIBUTE.CUM_YIELD,
  ATTRIBUTE.WELL_FORECAST_YIELD_ULTIMATE,
  ATTRIBUTE.CUM_VENT_FLARE_VOL,
  ATTRIBUTE.DISPOSITION_TYPE,
  ATTRIBUTE.LAST_INJECTION_TYPE,
  ATTRIBUTE.LAST_INJECTION_FORMATION,
  ATTRIBUTE.CUM_INJECTION_GAS,
  ATTRIBUTE.CUM_INJECTION_LIQUID,
  ATTRIBUTE.DENSITY,
  ATTRIBUTE.NEUTRON_POROSITY,
  ATTRIBUTE.VSHALE,
  ATTRIBUTE.SONIC,
  ATTRIBUTE.RESISTIVITY,
  ATTRIBUTE.LOE_COST,
  ATTRIBUTE.GPT_COST,
  ATTRIBUTE.WATER_COST,
  ATTRIBUTE.FIXED_COST,
  ATTRIBUTE.DRILLING_COMPLETION_CAP_EX,
  ATTRIBUTE.INFRA_CAP_EX,
  ATTRIBUTE.WORKING_INTEREST,
  ATTRIBUTE.ROYALTY,
  ATTRIBUTE.INTERNAL_RATE_OF_RETURN,
  ATTRIBUTE.BREAK_EVEN_PRICE,
  ATTRIBUTE.NET_PRESENT_VALUE,
  ATTRIBUTE.PAYOUT,
];

export const COLOR_BY_ATTRIBUTE_INFO = {
  ...ATTRIBUTE.ATTRIBUTE_INFO_BY_KEY,
  [UNIFORM_COLOR.key]: UNIFORM_COLOR,
};

export const SIZE_BY_ATTRIBUTE_LIST = [
  ATTRIBUTE.ACID_AMOUNT,
  ATTRIBUTE.BOE_MAX_PER_FT,
  ATTRIBUTE.MAX_BOE,
  ATTRIBUTE.WELL_FORECAST_BOE_REMAINING,
  ATTRIBUTE.CUM_BOE,
  ATTRIBUTE.WELL_FORECAST_BOE_ULTIMATE,
  ATTRIBUTE.PERF_INTERVAL_BOTTOM,
  ATTRIBUTE.PERF_INTERVAL_TOP,
  ATTRIBUTE.ELEVATION_GROUND,
  ATTRIBUTE.ELEVATION_KELLY_BUSHING,
  ATTRIBUTE.EUR_PER_FT,
  ATTRIBUTE.FRAC_FLUID_AMOUNT,
  ATTRIBUTE.FRAC_FLUID_AMOUNT_PER_FT,
  ATTRIBUTE.CUM_INJECTION_GAS,
  ATTRIBUTE.MAX_GAS,
  ATTRIBUTE.MAX_GAS_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_GAS_REMAINING,
  ATTRIBUTE.CUM_GAS,
  ATTRIBUTE.WELL_FORECAST_GAS_ULTIMATE,
  ATTRIBUTE.CUM_GOR,
  ATTRIBUTE.WELL_FORECAST_GOR_ULTIMATE,
  ATTRIBUTE.LATERAL_LENGTH,
  ATTRIBUTE.CUM_INJECTION_LIQUID,
  ATTRIBUTE.MEASURED_DEPTH,
  ATTRIBUTE.MAX_OIL,
  ATTRIBUTE.MAX_OIL_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_OIL_REMAINING,
  ATTRIBUTE.CUM_OIL,
  ATTRIBUTE.WELL_FORECAST_OIL_ULTIMATE,
  ATTRIBUTE.PROPPANT_AMOUNT,
  ATTRIBUTE.PROPPANT_AMOUNT_PER_FT,
  ATTRIBUTE.TOTAL_VERTICAL_DEPTH,
  ATTRIBUTE.CUM_VENT_FLARE_VOL,
  ATTRIBUTE.WELL_FORECAST_WATER_CUT_ULTIMATE,
  ATTRIBUTE.MAX_WATER,
  ATTRIBUTE.MAX_WATER_PLUS_2,
  ATTRIBUTE.WELL_FORECAST_WATER_REMAINING,
  ATTRIBUTE.CUM_WATER,
  ATTRIBUTE.WELL_FORECAST_WATER_ULTIMATE,
  ATTRIBUTE.OIL_EUR_PER_FT,
  ATTRIBUTE.GAS_EUR_PER_FT,
  ATTRIBUTE.OIL_CUM_PER_FT,
  ATTRIBUTE.GAS_CUM_PER_FT,
  ATTRIBUTE.WATER_CUM_PER_FT,
  ATTRIBUTE.BOE_CUM_PER_FT,
  ATTRIBUTE.BOE_IP90,
];

export const SIZE_BY_ATTRIBUTE_INFO = {
  ...ATTRIBUTE.ATTRIBUTE_INFO_BY_KEY,
};
export const MAP_SETTINGS = { key: "mapSettings", label: "MAP SETTINGS" };
export const LEGEND = { key: "legend", label: "LEGEND" };
export const MAP_OVERLAYS = [MAP_SETTINGS, LEGEND];

export const MAP_SCALE_BAR_UNITS = [
  { key: "imperial", label: "Imperial" },
  { key: "metric", label: "Metric" },
];

export const STYLING_API_KEY_MAPPER = {
  [WELL_SPOTS]: {
    request: "SURFACE",
    dataKey: "ParentWellID",
  },
  [BOTTOM_WELL_SPOTS]: {
    request: "BOTTOM",
    dataKey: "BottomWellboreID",
  },
  [PERMIT_SPOTS]: {
    request: "PERMIT",
    dataKey: "PermitID",
  },
};

export const DEFAULT_TOP_COLORS = [
  [0, 170, 171],
  [85, 0, 171],
  [170, 120, 120],
  [142, 68, 238],
  [193, 124, 21],
  [133, 186, 133],
  [181, 48, 48],
  [254, 203, 120],
  [116, 117, 0],
  [206, 147, 216],
];

export const DEFAULT_GRADIENT_COLORS = [
  [0, 22, 255], // low
  [186, 37, 44], // high
];

export const DEFAULT_ATTRIBUTE_COLOR = [128, 128, 128];

export const DEFAULT_BUBBLE_MAP_SIZES_IN_PIXELS = [150, 1000];

export const DRAW_TO_SELECT_MODE = "Draw To Select";
export const DRAW_POLYGON_MODE = "Draw Polygon";
export const DRAW_SELECT_DEFAULT = "Circle";
export const DRAW_MODE_NONE = "None";
export const DRAW_MODE_RECTANGLE = "Rectangle";
export const DRAW_MODE_CIRCLE = "Circle";
export const DRAW_MODE_POLYGON = "Polygon";

export const CULTURE_LAYERS = [
  BOUGUER_ALY,
  ISOSTATIC_ALY,
  MAGNETIC_ALY,
  SHADED_ALY,
  LAND_GRIDS,
  MAP_SEARCH_PIPELINES,
  OFFSHORE_BLOCKS,
  MAJOR_BASINS,
  TGS_SURVEYS,
  STRATIGRAPHIC_MODELS_AREA,
  TRENDS,
];

export const GEO_EDGES_LAYERS = [
  WOODFORD_LIMIT,
  STRUCTURAL_ELEMENTS,
  SCOOP_STACK_PLAY_AREAS,
  WOODFORD_ISOPACH,
  WOODFORD_STRUCTURE,
  WOODFORD_FIELDS,
  WOODFORD_MATURITY,
];

export const WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL = {
  key: "HighlightSelectedWell",
  label: "Highlight Selected Spots",
} as const;
export const WELL_STYLING_WELL_PERMIT_CARD_CONTROL = {
  key: "WellPermitCard",
  label: "Well/Permit Card",
} as const;
export const WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL = {
  key: "SurfaceHoleLocation",
  label: "Well Surface Hole Location",
} as const;
export const WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL = {
  key: "BottomHoleLocation",
  label: "Well Bottom Hole Location",
} as const;
export const WELL_STYLING_WELL_PATHS_CONTROL = {
  key: "WellPaths",
  label: "Well Paths",
} as const;
export const WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL = {
  key: "PermitSurfaceHoleLocation",
  label: "Permit Surface Hole Location",
} as const;
export const WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL = {
  key: "PermitBottomHoleLocation",
  label: "Permit Bottom Hole Location",
} as const;
export const WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL = {
  key: "ApplyColorToPermits",
  label: "Apply Color to Permits",
} as const;

export const WELL_STYLING_PERMITS_ONLY = [
  WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL,
  WELL_STYLING_WELL_PERMIT_CARD_CONTROL,
  WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL,
  WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL,
];

export const WELL_STYLING_WELLS_ONLY = [
  WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL,
  WELL_STYLING_WELL_PERMIT_CARD_CONTROL,
  WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL,
  WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL,
  WELL_STYLING_WELL_PATHS_CONTROL,
];

export const WELL_STYLING_WELLS_AND_PERMITS = [
  WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL,
  WELL_STYLING_WELL_PERMIT_CARD_CONTROL,
  WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL,
  WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL,
  WELL_STYLING_WELL_PATHS_CONTROL,
  WELL_STYLING_PERMIT_SURFACE_HOLE_LOCATION_CONTROL,
  WELL_STYLING_PERMIT_BOTTOM_HOLE_LOCATION_CONTROL,
  WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL,
];
