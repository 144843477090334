import { useEffect, useState } from "react";

import { CircularProgress, Typography, styled } from "@mui/material";

import { LayerLegendItem } from "../../../types/map/layers/useGetTgsLayerLegendApi";

import useGetTgsLayerLegend from "../../../customHooks/map/layers/useGetTgsLayerLegend";

interface CarbonStorageLegendContentProps {
  basinDisplayName: string;
  formationName: string;
}
const SquareIcon = styled("span")(({ color }: { color: string }) => ({
  backgroundColor: color,
  width: 12.95,
  height: 12,
  border: "1px solid #FFFFFF26",
  borderRadius: 2,
}));

const CarbonStorageLegendContent = ({
  basinDisplayName,
  formationName,
}: CarbonStorageLegendContentProps) => {
  const [legendItems, setLegendItems] = useState<LayerLegendItem[]>([]);

  const { isLoading, data, error, getTGSLegend } = useGetTgsLayerLegend();

  useEffect(() => {
    getTGSLegend({ layerName: formationName });
  }, [formationName]);

  useEffect(() => {
    if (isLoading || !data || error) return;
    setLegendItems(data);
  }, [isLoading, data, error]);

  return (
    <>
      <Typography className="layer-info-sub-header">
        {basinDisplayName} (Mt)
      </Typography>
      {isLoading ? (
        <div className="layer-info-loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="layer-info-list multi-cols">
          {legendItems.map((item) => (
            <div key={item.label} className="layer-info-item carbon-storage">
              <div className="layer-info-symbol">
                <SquareIcon color={item.color} />
              </div>
              <div className="layer-info-label">{item.label}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CarbonStorageLegendContent;
