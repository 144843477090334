import { FC, useEffect } from "react";

import { Divider, Tab, Tabs, Typography } from "@mui/material";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";

import { ATTRIBUTES_TAB, UWI_TAB } from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useExportCardStore from "../../../store/exportCard/exportCardStore";
import useExportMapStore from "../../../store/exportMap/exportMapStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import useSearchPanel from "../../../customHooks/search/useSearchPanel";

import AttributeTabIcon from "../../common/icons/searchPanel/AttributeTabIcon";
import UWITabIcon from "../../common/icons/searchPanel/UWITabIcon";
import SaveSearchAttributesDialog from "../../modals/saveSearch/SaveSearchAttributesDialog";
import PanelDrawer from "../common/PanelDrawer";
import AttributesTabPanel from "./attributes/AttributesTabPanel";
import UWITabPanel from "./uwi/UWITabPanel";

const SearchPanel: FC<PanelDrawerProps> = ({ open, handleCloseMenuClick }) => {
  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  const activePanel = usePanelsStore((state) => state.activePanel);
  const activeSearchPanelTab = usePanelsStore(
    (state) => state.activeSearchPanelTab
  );
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const allGridData = useDataGridStore((state) => state.allGridData);

  const resetCurrentQBInfo = useQueryBuilderStore(
    (state) => state.resetCurrentQBInfo
  );

  const updateExportCard = useExportCardStore(
    (state) => state.updateExportCard
  );
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );

  const updateExportMapWellUwiList = useExportMapStore(
    (state) => state.updateExportMapWellUwiList
  );

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const { searchTitle, searchTypeByActiveTab, isFilterIncluded } =
    useSearchPanel();

  useEffect(() => {
    resetCurrentQBInfo("", "isUpdated");
    resetCurrentQBInfo("", "options");
  }, [drawnPolygons, shapeId]);

  useEffect(() => {
    if (activeSearchPanelTab !== ATTRIBUTES_TAB) {
      toggleIsOpenQBEditorPanel(false);
    }
  }, [activeSearchPanelTab]);

  useEffect(() => {
    updateExportCard(false);
  }, [activePanel]);

  useEffect(() => {
    if (allGridData.length > 0 && !uploadedUWIFile) {
      const exportUwi: string[] = [];
      const exportMapUwi: string[] = [];
      allGridData.forEach((data) => {
        if (data.UWI) exportUwi.push(data.UWI);

        if (data.UWI && data.RecordType === RECORD_TYPES.WELL) {
          exportMapUwi.push(data.UWI);
        }
      });
      updateExportUwiList(exportUwi);
      updateExportMapWellUwiList(exportMapUwi);
    } else if (uploadedUWIFile) {
      updateExportUwiList([]);
      updateExportMapWellUwiList([]);
    }
  }, [allGridData, uploadedUWIFile]);

  return (
    <PanelDrawer
      open={open}
      className="search-panel"
      hidePanelControl={isOpenQBEditorPanel}
      handleCloseMenuClick={handleCloseMenuClick}
    >
      <div className="drawer-container">
        <div className="sub-header">
          <Typography variant="h6" className="header-name">
            {searchTitle}
          </Typography>
        </div>
        <div className="tabs-container">
          <Tabs
            value={activeSearchPanelTab}
            onChange={(e, newValue) => updateActiveSearchPanelTab(newValue)}
            aria-label="sidebar-tabs"
            className="nav-tabs"
          >
            <Tab
              icon={
                <AttributeTabIcon
                  isSelected={activeSearchPanelTab === ATTRIBUTES_TAB}
                />
              }
              iconPosition="start"
              label="Attributes"
            />
            <Tab
              icon={
                <UWITabIcon isSelected={activeSearchPanelTab === UWI_TAB} />
              }
              iconPosition="start"
              label="UWI"
            />
          </Tabs>
          <Divider />
        </div>
        <AttributesTabPanel hidden={activeSearchPanelTab !== ATTRIBUTES_TAB} />
        <UWITabPanel hidden={activeSearchPanelTab !== UWI_TAB} />
      </div>
      <SaveSearchAttributesDialog
        isFilterIncluded={isFilterIncluded}
        searchType={searchTypeByActiveTab}
      />
    </PanelDrawer>
  );
};
export default SearchPanel;
