import { FormControlLabel } from "@mui/material";

import classNames from "classnames";

import CustomCheckbox from "../common/CustomCheckbox";
import TooltipInfoIcon from "../common/icons/TooltipInfoIcon";
import { CustomTooltip } from "./CustomTooltip";

interface MapSettingsCheckboxProps {
  label: string;
  tooltipText?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  handleChange: () => void;
}

const MapSettingsCheckbox = ({
  label,
  tooltipText,
  isChecked,
  isDisabled = false,
  handleChange,
}: MapSettingsCheckboxProps) => {
  return (
    <FormControlLabel
      className="mapsetting-label"
      control={
        <CustomCheckbox
          checked={isChecked}
          disabled={isDisabled}
          onChange={handleChange}
        />
      }
      label={
        <>
          <span className={classNames({ "disabled-option": isDisabled })}>
            {label}
          </span>
          {tooltipText && isDisabled && (
            <CustomTooltip title={tooltipText} placement="left" arrow>
              <span className="tooltip-info">
                <TooltipInfoIcon />
              </span>
            </CustomTooltip>
          )}
        </>
      }
    />
  );
};

export default MapSettingsCheckbox;
